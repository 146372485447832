<template>
  <div class="course-desc-content" v-if="course">
    <div
      class="row wrap-reverse default-card-border default-rounded bg-white q-pa-lg"
    >
      <div class="col-12 col-md-8">
        <h5
          class="text-weight-bold text-uppercase text-blue-grey-9 q-mb-md"
          v-html="course.name"
        />

        <p
          class="text-blue-grey-9 q-mb-md q-mt-lg text-justify"
          v-html="course.description"
        />
        <div class="row q-mb-md">
          <q-btn
            unelevated
            rounded
            class="q-mr-sm"
            color="orange-3"
            :label="course.category.category"
          />
        </div>
      </div>
      <div
        class="col-12 col-md-4 self-center"
        :class="$q.screen.width >= 800 ? 'q-pl-lg' : ''"
      >
        <q-img class="default-rounded" :src="imageThumb" :ratio="16 / 9" />
      </div>
    </div>
    <div class="row q-mt-lg wrap-reverse">
      <div
        :class="{
          'q-mt-lg': $q.screen.width < 800,
          'col-12': $q.screen.width < 800,
          'col-8': $q.screen.width >= 800,
        }"
      >
        <div
          class="default-card-border default-rounded bg-white q-pa-lg q-mb-md"
          v-if="what_will_learn.length"
        >
          <div
            class="text-h6 text-uppercase"
            v-html="$t('O que você vai aprender')"
          />

          <content-control>
            <div class="row">
              <div
                class="row no-wrap text-grey-7 q-mt-md col-sm-6 col-xs-12"
                v-for="(r, key) in what_will_learn"
                :key="`what_will_learn-${key}`"
              >
                <q-icon class="col-1 self-center" name="check" size="1.5em" />
                <div class="col-11">{{ r }}</div>
              </div>
            </div>
          </content-control>
        </div>
        <div
          class="default-card-border default-rounded bg-white q-pa-lg q-mb-lg"
          v-if="course.summary"
        >
          <p class="text-weight-bold text-uppercase text-blue-grey-9 q-mb-md">
            Descritivo do curso
          </p>
          <content-control>
            <div v-html="course.summary" />
          </content-control>
        </div>
        <div
          class="default-card-border default-rounded bg-white q-pa-lg q-mb-md"
          v-if="requisites.length"
        >
          <div
            class="text-h6 text-uppercase"
            v-html="$t('   Pré-requisitos')"
          />
          <content-control>
            <div class="row">
              <div
                class="row no-wrap text-grey-7 q-mt-md col-sm-6 col-xs-12"
                v-for="(r, key) in requisites"
                :key="`requisites-${key}`"
              >
                <q-icon class="col-1 self-center" name="check" size="1.5em" />
                <div class="col-11">{{ r }}</div>
              </div>
            </div>
          </content-control>
        </div>
        <div
          class="default-card-border default-rounded bg-white q-pa-lg q-mb-md"
          v-if="course.related_courses.length"
        >
          <p class="text-weight-bold text-uppercase text-blue-grey-9">
            Cursos Relacionados
          </p>
          <div class="row q-pt-lg q-col-gutter-xs full-width justify-start">
            <div
              class="course-display"
              v-for="related in course.related_courses"
              :key="`related-${related.id}`"
            >
              <course-item
                class="no-shadow"
                :course="related"
                :key="`course-related-item-${related.id}`"
                :style="{ maxWidth: '200px' }"
              />
            </div>
          </div>
        </div>
        <div
          class="default-card-border default-rounded bg-white q-pa-lg q-mb-md"
          v-if="course.creators.length"
        >
          <div
            class="text-h6 text-uppercase q-mb-md"
            v-html="$t('Autores deste curso')"
          />
          <div
            class="row"
            v-for="creator in course.creators"
            :key="`creator-${creator.id}`"
          >
            <q-card flat class="bg-grey-2 q-mb-sm full-width default-rounded">
              <q-item :clickable="false">
                <q-item-section avatar>
                  <q-avatar>
                    <img :src="creator.image" />
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label>{{ creator.presentation_name }}</q-item-label>
                  <q-item-label caption>{{ creator.bio }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-card>
          </div>
          <!--
          <div class="column text-left">
            <p class="text-weight-bold text-blue-grey-8">
              Conquiste seus sonhos aqui
            </p>
            <p class="text-blue-grey-8">
              Aprender mantém você na liderança<br />
              Obtenha habilidades sob demanda para impressionar qualquer pessoa
            </p>
          </div>
        --></div>
      </div>
      <div
        :class="{
          'col-12': $q.screen.width < 800,
          'col-4': $q.screen.width >= 800,
        }"
      >
        <div
          class="default-card-border full-height default-rounded bg-white q-pa-lg"
          :class="{
            'q-ml-lg': $q.screen.width >= 800,
            column: $q.screen.width < 800,
          }"
        >
          <q-btn
            unelevated
            color="dark"
            @click="confirm = true"
            :label="$t('missions.enroll')"
            class="default-rounded text-weight-bolder full-width q-pa-md"
            style="font-size: 14pt;"
          />
          <div class="column q-mt-md">
            <div
              v-if="course.points_if_complete"
              class="text-red-8 text-center"
              :class="{
                'q-mt-md': $q.screen.width <= 800,
              }"
            >
              <span style="font-size: 12pt;">
                <q-icon name="monetization_on" class="label-color" />
                <span class="text-weight-light"
                  >Ganhe até
                  <b
                    >{{ parseFloat(course.points_if_complete) }} créditos +
                    variável</b
                  >
                </span>
                ao completar o curso</span
              >
            </div>

            <p class="text-weight-bold q-mt-lg text-uppercase"></p>
            <div
              class="text-h6 text-uppercase"
              v-html="$t('Este treinamento inclui:')"
            />

            <div
              class="row no-wrap text-grey-7 q-mt-md"
              v-show="contentCourse.video"
            >
              <q-icon
                class="col-1 self-center q-mr-lg"
                name="videocam"
                size="1.5em"
              />
              <div class="col-11">Arquivos de vídeo</div>
            </div>
            <div
              class="row no-wrap text-grey-7 q-mt-md"
              v-show="contentCourse.post"
            >
              <q-icon
                class="col-1 self-center q-mr-lg"
                name="sms"
                size="1.5em"
              />
              <div class="col-11">Arquivos para estudos</div>
            </div>
            <div
              class="row no-wrap text-grey-7 q-mt-md"
              v-show="contentCourse.questionnaire"
            >
              <q-icon
                class="col-1 self-center q-mr-lg"
                name="playlist_add"
                size="1.5em"
              />
              <div class="col-11">Questionário de conteúdo</div>
            </div>
            <div class="row no-wrap text-grey-7 q-mt-md">
              <q-icon
                class="col-1 self-center q-mr-lg"
                name="school"
                size="1.5em"
              />
              <div class="col-11">Possui certificado</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="confirm" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="school" color="dark" text-color="white" />
          <span class="q-ml-sm">Você está liberando esse curso</span>
          <div class="column">
            <p class="q-mt-lg text-weight-bold text-blue-grey-9 text-uppercase">
              Curso: {{ course.name }}
            </p>
            <p
              class="q-mt-lg text-weight-bold text-blue-grey-9 q-mb-md"
              v-if="course.points_to_enroll"
            >
              Serão cobrados o valor de
              {{ course.points_to_enroll }} créditos da sua conta,
            </p>
            <p class="q-mt-lg text-weight-bold text-blue-grey-9 q-mb-md" v-else>
              Nenhum crédito será cobrado da sua conta
            </p>
            <p class="q-mt-lg text-weight-bold text-blue-grey-9 q-mb-md">
              Confirme para continuar
            </p>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="$t('global.cancel')" color="dark" v-close-popup />
          <q-btn
            label="Confirmar"
            color="dark"
            @click="enrollCourse"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import CourseItem from "@/modules/main/pages/courses/components/CourseItem";
import ContentControl from "@/modules/main/components/contentControl";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { isJson } from "@/shared/helpers/stringManipulations";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { filter, isArray } from "lodash";

const { useActions } = createNamespacedHelpers("courses");
const UseAssents = createNamespacedHelpers("assents");

export default {
  name: "show",

  components: { CourseItem, ContentControl },

  setup() {
    const route = useRoute(),
      router = useRouter();

    const { fetchCourseBySlug, actionToEnrollCourse } = useActions([
        "fetchCourseBySlug",
        "actionToEnrollCourse",
      ]),
      course = ref(null),
      in_request = ref(false),
      imageFetchFailed = ref(false),
      confirm = ref(false);

    const { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]);

    const imageThumb = computed(() => {
      if (imageFetchFailed.value || course.value === null) {
        return ImgNotFound;
      } else if (isJson(course.value.images_videos)) {
        return JSON.parse(course.value.images_videos).capa_medium;
      } else return course.value.images_videos;
    });

    const what_will_learn = computed(() => {
      if (course.value === null) return [];
      else if (isJson(course.value.what_will_learn)) {
        return JSON.parse(course.value.what_will_learn);
      } else if (isArray(course.value.what_will_learn)) {
        return course.value.what_will_learn;
      } else return course.value.what_will_learn;
    });

    const requisites = computed(() => {
      if (course.value === null) return [];
      else if (isJson(course.value.skills_required)) {
        return JSON.parse(course.value.skills_required);
      } else if (isArray(course.value.skills_required)) {
        return course.value.skills_required;
      } else return course.value.skills_required;
    });

    const contentCourse = computed(() => {
      if (course.value === null) {
        return {
          video: false,
          post: false,
          questionnaire: false,
        };
      } else {
        return {
          video:
            filter(course.value.contents, (c) => {
              return c.contentable_type.toLowerCase().match("video") !== null;
            }).length > 0,
          post:
            filter(course.value.contents, (c) => {
              return c.contentable_type.toLowerCase().match("text") !== null;
            }).length > 0,
          questionnaire:
            filter(course.value.contents, (c) => {
              return (
                c.contentable_type.toLowerCase().match("questionary") !== null
              );
            }).length > 0,
        };
      }
    });

    const fetchCourse = () => {
      in_request.value = true;
      fetchCourseBySlug({
        slug: route.params.slug,
      })
        .then((c) => (course.value = c))
        .finally(() => (in_request.value = false));
    };

    const enrollCourse = () => {
      actionToEnrollCourse({ id: course.value.id })
        .then((enroll) => {
          notifySuccess();
          router.push({ name: "course", params: { id: enroll.id } });
        })
        .catch(({ errors }) => {
          notifyError();
          if (errors) Object.values(errors).forEach(notifyError);
        });
    };

    onMounted(() => fetchCourse());

    return {
      expand_description: ref(false),
      course,
      confirm,
      what_will_learn,
      requisites,
      contentCourse,
      ImgNotFound,
      imageThumb,
      enrollCourse,
    };
  },
};
</script>

<style lang="scss">
.course-desc-content {
  .win-points {
    font-size: 0.9em;
  }
}
</style>
