<template>
  <div
    class="content-description overflow-hidden"
    ref="containerRef"
    :class="{ expanded: expand_description }"
    :style="{ height: expand_description ? 'auto' : height }"
  >
    <div id="slot-content-id" ref="contentRef">
      <slot />
    </div>
  </div>

  <div class="full-width text-center" v-if="!hideControl">
    <q-btn
      v-if="!expand_description"
      flat
      :ripple="false"
      @click="expand_description = true"
      color="primary"
      :label="$t('global.show_more')"
    />
    <q-btn
      v-if="expand_description"
      flat
      :ripple="false"
      @click="expand_description = false"
      color="primary"
      :label="$t('global.show_less')"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "ContentControl",

  props: {
    height: {
      type: String,
      default: "300px",
    },
  },

  setup() {
    const contentRef = ref(null),
      containerRef = ref(null),
      hideControl = ref(false),
      expand_description = ref(false);

    onMounted(() => {
      if (contentRef.value.offsetHeight < containerRef.value.offsetHeight) {
        hideControl.value = true;
        expand_description.value = true;
      }
    });

    return {
      expand_description,
      hideControl,
      contentRef,
      containerRef,
    };
  },
};
</script>

<style scoped lang="scss">
.content-description {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 40px;
    background: #ffffff94;
    position: absolute;
    bottom: 0;
    z-index: 99999999;
  }
  &.expanded {
    &:before {
      content: "";
      display: none;
    }
  }
}
</style>
